import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import KeyIcon from '@mui/icons-material/Key';
import MessageIcon from '@mui/icons-material/Message';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock'
import SwipeableViews from 'react-swipeable-views';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as login from '../../modules/login';
import * as loginPassword from '../../modules/login-password';
import * as loginSMS from '../../modules/login-sms';
import * as loginEmail from '../../modules/login-email';
import * as loginOtp from '../../modules/login-otp';
import * as settings from '../../modules/settings';
import * as rest from '../../modules/rest';
import * as resetPassword from '../../modules/reset-password';
import AuthenticateOTP from './authenticate-otp';
import AuthenticatePassword from './authenticate-password';
import AuthenticateSMS from './authenticate-sms';
import AuthenticateEmail from './authenticate-email';
import AuthenticateExternals from './authenticate-externals';
import { getSiteLink } from '../../lib/util';
import AuthenticateBase from './authenticate-base';
import useWindowDimensions from '../../lib/react-window-dimensions';
import { MenuItem } from '../util';
import OpenID from '../../icons/OpenID';

function NoMatch() {
  return (
    <div>
      <Alert severity="error">
        404 NOT FOUND
      </Alert>
    </div>
  );
}

function TabContainer({ children, dir }) {
  return (
    <Typography className="layout-align-center-stretch layout-row" component="div" dir={dir}>
      {children}
    </Typography>
  );
}

function subpathOf(prefix, path) {
  const ix = path.lastIndexOf(`${prefix}/`);
  if (ix >= 0) {
    return path.substring(ix + prefix.length + 1);
  } else {
    return '';
  }
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

function AuthenticateRequest({
  passwordExpired,
  loginMode,
  showDomainSelect,
  noRemember,
  requestLoginPassword,
  requestLoginSMS,
  requestLoginEmail,
  requestLoginOtp,
  setLoginMode,
  isRetryingOTP,
  setRetryingOTP,
  resetError,
  hasChallenge,
  setChallenge,
  strict,
  email,
  phone,
  otp,
  children,
  enableSignup,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const server = getSiteLink('admin');
  const { width } = useWindowDimensions();
  const location = useLocation();

  // When we come back from Okta authentication, the challenge was reinserted into the URL *after* we've processed
  // query parameters, so it's not present in the redux store. Handle this special case here
  useEffect(() => {
    if (!hasChallenge) {
      // The query parameters were restored after we process them in index.js, so we have to pick up the
      // challenge parameter again directly from the query
      const hashQuery = new URL(window.location.hash.substring(1), window.location).search.substring(1);
      const query = new URLSearchParams(hashQuery);
      const challenge = query.get('challenge');
      if (challenge) {
        setChallenge(challenge);
      }
    }
  }, [hasChallenge]);

  useEffect(() => {
    const subpath = subpathOf('login', location.pathname);

    if ((subpath && subpath !== loginMode) || (isMobile && subpath === '')) {
      setLoginMode(subpath);
      resetError();
    }
  });

  useEffect(() => {
    // If we're not on the OTP page, make sure we're no longer
    // retrying getting the OTP code.
    if (loginMode != 'otp' && isRetryingOTP) {
      setRetryingOTP(false);
    }
  }, [loginMode, isRetryingOTP]);

  function modeIndex() {
    switch (loginMode) {
      case 'sms':
        return 1;
      case 'email':
        return 2;
      case 'otp':
        return 3;
      case 'external':
        return 4;
      default:
        return 0;
    }
  }
  function handleLoginMode(event, value) {
    setLoginMode(value);
    resetError();
  };
  function handleChangeIndex(ix) {
    switch (ix) {
      case 1:
        setLoginMode('sms');
        break;
      case 2:
        setLoginMode('email');
        break;
      case 3:
        setLoginMode('otp');
        break;
      case 4:
        setLoginMode('external');
        break;
      default:
        setLoginMode('password');
        break;
    }
  };

  if (hasChallenge) {
    function nonstrictOr(cond) {
      return !strict || cond;
    }

    let subtitle;
    if (showDomainSelect) {
      subtitle = domain => domain;
    } else {
      const selectText = (width >= 300)
        ? t('Select authentication method')
        : t('Select method');
      subtitle = () => selectText;
    }

    return (
      <div>
        {isMobile && <>
          <Routes>
            <Route path='/' element={
              <Paper sx={{ background: theme.palette.primary[`${theme.palette.mode}Gradient`]}}>
                <AuthenticateBase sx={{ width: '100%' }} subtitle={subtitle}>
                  <MenuItem icon={<MessageIcon />} path="sms">
                    {t('SMS')}
                  </MenuItem>

                  <MenuItem icon={<LockIcon />} path="otp">
                    {t('One-time code')}
                  </MenuItem>

                  <MenuItem icon={<EmailIcon />} path="email">
                    {t('Email')}
                  </MenuItem>

                  <MenuItem icon={<KeyIcon />} path="password">
                    {t('Password')}
                  </MenuItem>

                  <MenuItem icon={<OpenID />} path="external">
                    {t('External')}
                  </MenuItem>
                </AuthenticateBase>
              </Paper>} />
            <Route path='password' element={<AuthenticatePassword onSubmit={requestLoginPassword} />} />
            <Route path='sms' element={<AuthenticateSMS onSubmit={requestLoginSMS} />} />
            <Route path='email' element={<AuthenticateEmail onSubmit={requestLoginEmail} />} />
            <Route path='otp' element={<AuthenticateOTP />} />
            <Route path='external' element={<AuthenticateExternals onSubmit={() => { }} />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
          {children}
        </>}
        { !isMobile && <>
          <Typography>{t('Login using')}</Typography>
          <Tabs
            centered
            onChange={(event, value) => handleLoginMode(event, value)}
            value={loginMode}
          >
            {nonstrictOr(false) && <Tab value="password" label={t('Password')} icon={<KeyIcon />} />}
            {nonstrictOr(phone) && <Tab value="sms" label={t('SMS')} icon={<MessageIcon />} />}
            {nonstrictOr(email) && <Tab value="email" label={t('Email')} icon={<EmailIcon />} />}
            {nonstrictOr(otp) && <Tab value="otp" label={t('Onetime Code')} icon={<LockIcon />} />}
            {nonstrictOr(false) && <Tab value="external" label={t('External')} icon={<OpenID />} />}
          </Tabs>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={modeIndex()}
            onChangeIndex={ix => handleChangeIndex(ix)}
            style={{ marginBottom: 20 }}
          >
            <TabContainer dir={theme.direction}>
              <AuthenticatePassword onSubmit={requestLoginPassword} />
            </TabContainer>
            <TabContainer dir={theme.direction}>
              <AuthenticateSMS onSubmit={requestLoginSMS} />
            </TabContainer>
            <TabContainer dir={theme.direction}>
              <AuthenticateEmail onSubmit={requestLoginEmail} />
            </TabContainer>
            <TabContainer dir={theme.direction}>
              <AuthenticateOTP onSubmit={requestLoginOtp} />
            </TabContainer>
            <TabContainer dir={theme.direction}>
              <AuthenticateExternals onSubmit={() => { }} />
            </TabContainer>
          </SwipeableViews>
          {children}
          <div
            style={{
              display: 'flex',
              alignItems: 'column',
              marginLeft: 14,
              justifyContent: 'space-between',
            }}
          >
          </div>
          <Divider className="non-mobile" style={{ marginTop: 20 }} />
          {enableSignup && !strict && (<Typography className="non-mobile" color="primary" style={{ fontWeight: 'bold' }}>
            <span style={{ color: '#555' }}>{t('Don\'t have an account?')}</span>
              &nbsp;
            <a href={`${server}/#/signup`}>{t('Create an account')}</a>
          </Typography>)}
        </>}
      </div>
    );
    } else {
      return <div className="error">
        {t('No login challenge present')}
      </div>;
    }
}

export default connect(
  state => ({
    passwordExpired: login.getPasswordExpired(state),
    loginMode: login.getLoginMode(state),
    strict: settings.getStrict(state),
    phone: settings.getPhone(state),
    email: settings.getEmail(state),
    otp: settings.getOnetimeCode(state),
    isRetryingOTP: login.isRetryingOTP(state),
    token: resetPassword.getResetToken(state),
    badToken: resetPassword.isBadToken(state),
    hasChallenge: rest.getChallenge(state) != null,
    emailStatus: resetPassword.getEmailStatus(state),
    enableSignup: settings.getEnableSignup(state),
    showDomainSelect: settings.getDomainSelect(state),
    noRemember: settings.getNoRemember(state),
  }),
  {
    requestLoginPassword: loginPassword.requestLogin,
    requestLoginSMS: loginSMS.requestLogin,
    requestLoginEmail: loginEmail.requestLogin,
    requestLoginOtp: loginOtp.requestLogin,
    setChallenge: rest.setChallenge,
    setLoginMode: login.setLoginMode,
    setRetryingOTP: login.setRetryingOTP,
    resetError: login.resetError,
    doResetPassword: resetPassword.resetPassword,
  },
)(AuthenticateRequest);
