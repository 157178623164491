import { handleAction } from 'redux-actions';
import { combineReducers } from 'redux';

const MODULE = 'settings';

export const LOGO_SET = `${MODULE} : Logo set`;
export const STYLE_SET = `${MODULE} : Style set`;
export const AUTH_CODE_SET = `${MODULE} : Auth code set`;
export const EMAIL_SET = `${MODULE} : Email set`;
export const PHONE_SET = `${MODULE} : Phone set`;
//export const TIMEOUT_SET = `${MODULE} : Timeout set`;
export const REMEMBER_SET = `${MODULE} : Remember set`;
export const ENABLE_SIGNUP_SET = `${MODULE} : Enable signup set`;
export const STRICT_SET = `${MODULE} : Set the strict flag`;
export const AZURE_AD_SET = `${MODULE} : Set Azure AD flag`;
export const OKTA_SET = `${MODULE} : Set Okta flag`;
export const ONETIME_CODE_SET = `${MODULE} : Set Onetime code flag`;
export const DOMAIN_SELECT = `${MODULE} : Allow domain select`;
export const REDIRECT_URI_SET = `${MODULE} : Set redirect URI`;
export const NO_REMEMBER_SET = `${MODULE} : Set no remember option`;
export const SHOW_LANG = `${MODULE} : Show language selection`;
export const PASSWORD_RENEWAL_SET = `${MODULE} : Enable/disable password renewal`;
export const TENANT_SET = `${MODULE} : Tenant`;
export const DOMAIN_HINT_SET = `${MODULE} : Domain hint`;
export const OKTA_SETTINGS_SET = `${MODULE} : Okta settings`;

// Actions
export const setLogo = logo => ({ type: LOGO_SET, logo });
export const setStyle = style => ({ type: STYLE_SET, style });
export const setAuthCode = authCode => ({ type: AUTH_CODE_SET, authCode });
export const setEmail = email => ({ type: EMAIL_SET, email });
export const setPhone = phone => ({ type: PHONE_SET, phone });
//export const setTimeout = timeout => ({ type: TIMEOUT_SET, timeout });
export const setRemember = remember => ({ type: REMEMBER_SET, remember });
export const setEnableSignup = enableSignup => ({ type: ENABLE_SIGNUP_SET, enableSignup });
export const setStrict = payload => ({ type: STRICT_SET, payload });
export const setAzureAD = payload => ({ type: AZURE_AD_SET, payload });
export const setOkta = payload => ({ type: OKTA_SET, payload });
export const setOnetimeCode = payload => ({ type: ONETIME_CODE_SET, payload });
export const setDomainSelect = payload => ({ type: DOMAIN_SELECT, payload });
export const setRedirectUri = payload => ({ type: REDIRECT_URI_SET, payload });
export const setNoRemember = payload => ({ type: NO_REMEMBER_SET, payload });
export const setShowLang = payload => ({ type: SHOW_LANG, payload });
export const setPasswordRenewal = payload => ({ type: PASSWORD_RENEWAL_SET, payload });
export const setTenant = payload => ({ type: TENANT_SET, payload });
export const setDomainHint = payload => ({ type: DOMAIN_HINT_SET, payload });
export const setOktaSettings = payload => ({ type: OKTA_SETTINGS_SET, payload });


// Selectors
export const getLogo = state => state[MODULE].logo;
export const getStyle = state => state[MODULE].style;
export const getAuthCode = state => state[MODULE].authCode;
export const getEmail = state => state[MODULE].email;
export const getPhone = state => state[MODULE].phone;
//export const getTimeout = state => state[MODULE].timeout;
export const getRemember = state => state[MODULE].remember;
export const getRememberAsEnum = state => toRememberEnum(getNoRemember(state), getRemember(state));
export const getEnableSignup = state => state[MODULE].enableSignup;
export const getStrict = state => state[MODULE].strict;
export const getAzureAD = state => state[MODULE].azureAD;
export const getOkta = state => state[MODULE].okta;
export const getOnetimeCode = state => state[MODULE].onetimeCode;
export const getDomainSelect = state => state[MODULE].domainSelect;
export const getRedirectUri = state => state[MODULE].redirectUri;
export const getNoRemember = state => state[MODULE].noRemember;
export const getShowLang = state => state[MODULE].showLang;
export const getPasswordRenewal = state => state[MODULE].passwordRenewal;
export const getTenant = state => state[MODULE].tenant;
export const getDomainHint = state => state[MODULE].domainHint;
export const getOktaSettings = state => state[MODULE].oktaSettings;

export function getStoredRemember() {
  return toRememberEnum(localStorage.getItem('noRemember'), localStorage.getItem('remember') === 'true');
}

function toRememberEnum(noRemember, remember) {
  return noRemember
    ? 'no'
    : (remember ? 'indefinite' : 'session');
}

// Reducer
export default {
  [MODULE]: combineReducers({
    logo: handleAction(LOGO_SET, (_, action) => action.logo, ''),
    style: handleAction(STYLE_SET, (_, action) => action.style, ''),
    authCode: handleAction(AUTH_CODE_SET, (_, action) => action.authCode, ''),
    email: handleAction(EMAIL_SET, (_, action) => action.email, ''),
    phone: handleAction(PHONE_SET, (_, action) => action.phone, ''),
//    timeout: handleAction(TIMEOUT_SET, (_, action) => action.timeout, 10 * 60),
    remember: handleAction(REMEMBER_SET, (_, action) => action.remember, localStorage.getItem('remember') === 'true'),
    enableSignup: handleAction(ENABLE_SIGNUP_SET, (_, action) => action.enableSignup, false),
    strict: handleAction(STRICT_SET, (_, action) => action.payload, false),
    azureAD: handleAction(AZURE_AD_SET, (_, action) => action.payload, false),
    okta: handleAction(OKTA_SET, (_, action) => action.payload, false),
    onetimeCode: handleAction(ONETIME_CODE_SET, (_, action) => action.payload, false),
    domainSelect: handleAction(DOMAIN_SELECT, (_, action) => action.payload, false),
    redirectUri: handleAction(REDIRECT_URI_SET, (_, action) => action.payload, null),
    noRemember: handleAction(NO_REMEMBER_SET, (_, action) => action.payload, false),
    showLang: handleAction(SHOW_LANG, (_, action) => action.payload, true),
    passwordRenewal: handleAction(PASSWORD_RENEWAL_SET, (_, action) => action.payload, true),
    tenant: handleAction(TENANT_SET, (_, action) => action.payload, null),
    domainHint: handleAction(DOMAIN_HINT_SET, (_, action) => action.payload, null),
    oktaSettings: handleAction(OKTA_SETTINGS_SET, (_, action) => action.payload, JSON.parse(localStorage.getItem('okta-auth'))),
  }),
};
