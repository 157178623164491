import * as settings from '../modules/settings';

export default [
  ({ dispatch }) => next => (action) => {
    next(action);
    // Remember the setting for the future
    if (action.type === settings.REMEMBER_SET) {
      window.localStorage.setItem('remember', action.remember);
    }
    if (action.type === settings.OKTA_SETTINGS_SET && action.payload) {
      window.localStorage.setItem('okta-auth', JSON.stringify(action.payload));
    }
  },
];
