import React, { useCallback, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import { MSLogin } from '../../icons';
import { msalInstance, getLoginRequest } from '../../msal-config';
import AuthenticateBase from './authenticate-base';
import * as settings from '../../modules/settings';
import * as rest from '../../modules/rest';
import * as login from '../../modules/login';
import * as loginOidc from '../../modules/login-oidc';
import { useTranslation } from 'react-i18next';
import OktaAura from '../../icons/OktaAura';
import OktaLogo from '../../icons/OktaLogo';
import { useOktaAuth } from '@okta/okta-react';

function AuthenticateExternals({
  adAccount,
  challenge,
  isADAccountNotInGT,
  remember,
  setRemember,
  noRemember,
  requestLoginOidc,
  oktaLogin,
}) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = React.useState(false);
  const oa = useOktaAuth();

  const doLogin = useCallback(
    (providerFun) => {
      setDisabled(false);
      if (isADAccountNotInGT) {
        getLoginRequest().prompt = "select_account";
      }
      window.setTimeout(() => providerFun(), 10);
    },
    [setDisabled, isADAccountNotInGT]
  );
  const doEntraLogin = useCallback(
    () => doLogin(() => msalInstance.loginRedirect(getLoginRequest())),
    [doLogin]
  );
  const oktaCb = () =>
    doLogin(() => {
      oa?.oktaAuth.signInWithRedirect({
        originalUri: `/login/external?challenge=${challenge}`,
      });
    });
  const doOktaLogin = useCallback(oktaCb, [doLogin, oa?.oktaAuth]);

  // If we're logged in to okta, send the token to the auth server to try authenticate
  useEffect(() => {
    if (oa?.authState?.isAuthenticated) {
      requestLoginOidc({
        challenge,
        remember,
        idToken: oa?.authState.idToken.idToken,
      });
    }
  }, [challenge, oa?.authState, remember]);

  // If user requested direct login, do it
  useEffect(() => {
    if (oktaLogin) {
      doOktaLogin();
    }
  }, [oktaLogin, doOktaLogin]);

  let error = <></>;
  if (isADAccountNotInGT) {
    error = (
      <Alert severity="error">
        {adAccount && (
          <span>
            <span className="account-name">{adAccount}</span>{" "}
            {t("is not a GroupTalk account")}.
          </span>
        )}
        <span>{t("Try again to select alternate account")}</span>
      </Alert>
    );
  }
  return (
    <AuthenticateBase subtitle={(domain) => domain}>
      <div className="layout-column">
        <div className="layout-row">
          <div className="flex-20" />
          <IconButton
            disabled={disabled}
            onClick={() => doEntraLogin()}
            disableRipple
            variant="contained"
            color="primary"
            className="raisedPrimary flex-60"
            style={{ margin: "6px 0px" }}
          >
            <MSLogin
              viewBox="0 0 104 41"
              style={{ width: "104px", height: "41px" }}
            />
          </IconButton>
          <div className="flex-20" />
        </div>
        { oa &&
          <div className="layout-row">
            <div className="flex-20" />
            <Button
              disabled={disabled}
              onClick={() => doOktaLogin()}
              variant="outlined"
              color="primary"
              className="raisedPrimary flex-60"
              startIcon={<OktaAura />}
            >
              <OktaLogo />
            </Button>
            <div className="flex-20" />
          </div>
        }
        {error}
        {noRemember || (
          <FormControlLabel
            control={
              <Checkbox
                checked={remember}
                onChange={({ target }) => setRemember(target.checked)}
              />
            }
            label={t("Remember me")}
          />
        )}
      </div>
    </AuthenticateBase>
  );
}

export default connect(
  state => ({
    adAccount: login.getADAccount(state),
    challenge: rest.getChallenge(state),
    isADAccountNotInGT: login.isADAccountNotInGT(state),
    remember: settings.getRemember(state),
    noRemember: settings.getNoRemember(state),
    oktaLogin: settings.getOkta(state),
  }),
  {
    requestLoginOidc: loginOidc.requestLogin,
    setRemember: settings.setRemember,
  }
)(AuthenticateExternals);
